import React, { Component } from "react";
import { Header, Grid, Button, Icon, Table, Loader, Label } from "semantic-ui-react";
import { Media } from "./Media";
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { HueCircle19 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import PricesEditor from './PricesEditor'
import axios from 'axios';

export default class Prices extends Component {
  state = {
    product: {},
    prices: [],
    isLoading: true,
    isError: false,
  };

  loadProduct = async function (productId) {
    const response = await axios.get("/api/prodotti/" + productId);
    const product = response.data;
    this.setState({
      product: product
    });
  };

  loadPrices = async function (productId) {
    const response = await axios.get("/api/prodotti/" + productId + "/prezzi?limit=10000");
    const stores = {};
    const prices = response.data.map(function (price) {
      if (!stores[price.NegozioID]) {
        price.Aggiornabile = true;
        stores[price.NegozioID] = true;
      }
      return price;
    });
    this.setState({
      prices: prices
    });
  };

  loadData = async function (productId) {
    try {
      await this.loadProduct(productId);
      await this.loadPrices(productId);
      this.setState({
        isLoading: false,
        isError: false
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        isError: true
      });
    }
  }

  onPricesEditorClosed = async function (updated) {
    if (updated) {
      this.loadData(this.props.match.params.productId);
    }
  }

  componentDidMount = function () {
    this.loadData(this.props.match.params.productId);
  };

  render() {
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2">
              <Icon className="left floated" name='euro' />
              Prezzi
            </Header>
          </Grid.Column>
        </Grid.Row>
        {
          this.state.isLoading ?
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="center aligned"
              >
                <Loader active inline="centered" />
              </Grid.Column>
            </Grid.Row>
            :
            this.state.isError ?
              <Label color='red'>Error</Label>
              :
              <React.Fragment>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="center aligned"
                  >
                    <Header as="h3" content={this.state.product.NomeProdotto} />
                    <span>{this.state.product.Marca}</span>
                    <br />
                    <span>{this.state.product.Confezione}</span>
                    <span> </span>
                    <span>{this.state.product.Quantita}</span>
                  </Grid.Column>
                </Grid.Row>
                {
                  this.state.prices.length > 0 ?
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={6} verticalAlign="middle" style={{ overflowX: "auto" }}>
                        <Table definition unstackable textAlign="center">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell />
                              <Table.HeaderCell>Ultimo Acquisto</Table.HeaderCell>
                              <Table.HeaderCell>Miglior Prezzo</Table.HeaderCell>
                              <Table.HeaderCell>Media Pesata</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>Prezzo</Table.Cell>
                              <Table.Cell negative>
                                {this.state.product.PrezzoUltimo} € {this.state.product.PrezzoUltimoPromozione ? <Icon name='star' /> : ''}<br />
                              ({this.state.product.PrezzoUltimoUnitaPrincipale} €/{this.state.product.UnitaPrincipale})
                            </Table.Cell>
                              <Table.Cell positive>
                                {this.state.product.PrezzoMinimo} € {this.state.product.PrezzoMinimoPromozione ? <Icon name='star' /> : ''}<br />
                              ({this.state.product.PrezzoMinimoUnitaPrincipale} €/{this.state.product.UnitaPrincipale})
                            </Table.Cell>
                              <Table.Cell warning>
                                {this.state.product.PrezzoMedioPesato} €<br />
                              ({this.state.product.PrezzoMedioPesatoUnitaPrincipale} €/{this.state.product.UnitaPrincipale})
                            </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>Data</Table.Cell>
                              <Table.Cell negative>{this.state.product.PrezzoUltimoData}</Table.Cell>
                              <Table.Cell positive>{this.state.product.PrezzoMinimoData}</Table.Cell>
                              <Table.Cell warning />
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>Negozio</Table.Cell>
                              <Table.Cell negative>{this.state.product.PrezzoUltimoNegozioNomeCompleto}</Table.Cell>
                              <Table.Cell positive>{this.state.product.PrezzoMinimoNegozioNomeCompleto}</Table.Cell>
                              <Table.Cell warning />
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        <Media as='br' at="sm" />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={10}>
                        <Line data={{
                          datasets: Object.values([...this.state.prices].reverse().reduce(function (acc, price) {
                            if (!acc[price.NegozioID]) {
                              acc[price.NegozioID] = {
                                label: price.NegozioNomeCompleto,
                                fill: false,
                                lineTension: 0,
                                data: []
                              }
                            }
                            acc[price.NegozioID].data.push({
                              x: price.DaData,
                              y: price.PrezzoUnitario
                            })
                            if (price.DaData !== price.AData) {
                              acc[price.NegozioID].data.push({
                                x: price.AData,
                                y: price.PrezzoUnitario
                              })
                            }
                            return acc;
                          }, {}))
                        }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            legend: {
                              position: 'bottom'
                            },
                            scales: {
                              xAxes: [{
                                type: 'time',
                                distribution: 'linear',
                                time: {
                                  parser: "YYYY-MM-DD",
                                  minUnit: "day"
                                },
                              }]
                            },
                            plugins: {
                              colorschemes: {
                                scheme: HueCircle19,
                                custom: function (schemeColors) {
                                  const halfwayThrough = Math.ceil(schemeColors.length / 2);
                                  const newSchemeColors = [];
                                  for (let i = 0; i < halfwayThrough; i++) {
                                    newSchemeColors.push(schemeColors[i]);
                                    newSchemeColors.push(schemeColors[halfwayThrough + i]);
                                  }
                                  return newSchemeColors;
                                }
                              }
                            }
                          }}
                          height={300}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    :
                    undefined
                }
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <PricesEditor
                      product={this.state.product}
                      trigger={
                        <Button icon className="right floated">
                          <Icon name="plus" /> Aggiungi Prezzo
                        </Button>
                      }
                      onClose={this.onPricesEditorClosed.bind(this)}
                    />
                  </Grid.Column>
                </Grid.Row>
                {
                  this.state.prices.length > 0 ?
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Table celled compact textAlign="center" sortable>
                          <Media greaterThan="sm">
                            {(mediaClassNames, renderChildren) => {
                              return (
                                <Table.Header className={mediaClassNames}>
                                  {renderChildren ? <Table.Row>
                                    <Table.HeaderCell>Negozio</Table.HeaderCell>
                                    <Table.HeaderCell>Prezzo</Table.HeaderCell>
                                    <Table.HeaderCell>Da Data</Table.HeaderCell>
                                    <Table.HeaderCell>A Data</Table.HeaderCell>
                                    <Table.HeaderCell />
                                  </Table.Row> : null}

                                </Table.Header>
                              )
                            }}
                          </Media>

                          <Table.Body>
                            {
                              this.state.prices.map(function (price) {
                                return (
                                  <Table.Row key={price.ID}>
                                    <Table.Cell>
                                      <Media as="span" at="sm"><b>Negozio: </b></Media> {price.NegozioNomeCompleto}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Media as="span" at="sm"><b>Prezzo: </b></Media> {price.Prezzo} € ({price.PrezzoUnitario} €/{this.state.product.UnitaPrincipale}) {price.Promozione ? <Icon name='star' /> : ''}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Media as="span" at="sm"><b>Da Data: </b></Media> {price.DaData}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Media as="span" at="sm"><b>A Data: </b></Media> {price.AData}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {price.Aggiornabile ?
                                        <React.Fragment>
                                          <PricesEditor
                                            product={this.state.product}
                                            store={price.NegozioID}
                                            price={price.Prezzo}
                                            promotion={price.Promozione}
                                            trigger={
                                              <Button icon style={{ padding: "4px", width: "40px", marginRight: "10px" }}>
                                                <Icon name="check" />
                                              </Button>
                                            }
                                            onClose={this.onPricesEditorClosed.bind(this)}
                                          />
                                          <PricesEditor
                                            product={this.state.product}
                                            store={price.NegozioID}
                                            trigger={
                                              <Button icon style={{ padding: "4px", width: "40px" }}>
                                                <Icon name="refresh" />
                                              </Button>
                                            }
                                            onClose={this.onPricesEditorClosed.bind(this)}
                                          />
                                        </React.Fragment>
                                        :
                                        ''
                                      }
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }.bind(this))
                            }
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                    :
                    undefined
                }
              </React.Fragment>
        }
      </Grid>
    );
  }
}
