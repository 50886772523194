import React, { Component } from 'react';
import {
  Grid,
  Header,
  Table,
  Loader,
  Label
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import axios from 'axios';

export default class ReportShelfLife extends Component {

  state = {
    response: [],
    queryInProgress: true,
    queryInError: false
  };

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({
        response: res ,
        queryInProgress: false,
        queryInError: false
      }))
      .catch(err => {
        this.setState({
          queryInProgress: false,
          queryInError: true
        });
      });
  }

  callApi = async function () {
    const response = await axios.get("/api/ReportShelfLife");

    const body = response.data;

    return body;
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content="Prodotti in scadenza" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
          {this.state.queryInProgress ? (
            <Loader active inline="centered" />
          ) : this.state.queryInError ? (
            <Label color='red'>Error</Label>
          ) : (this.state.response.length > 0 ? (
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Prodotto</Table.HeaderCell>
                  <Table.HeaderCell>Magazzino</Table.HeaderCell>
                  <Table.HeaderCell>Scadenza</Table.HeaderCell>
                  <Table.HeaderCell>Quantità</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.response.map((row, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Link to={'/products?search=' + row.NomeProdottoCompleto}>
                          {row.NomeProdottoCompleto}
                        </Link>
                      </Table.Cell>
                      <Table.Cell singleLine>{row.Magazzino}</Table.Cell>
                      <Table.Cell singleLine>{row.DataScadenza}</Table.Cell>
                      <Table.Cell singleLine textAlign='right'>{row.Quantita}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          ) : (
            <Label>No data</Label>
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    );
  }
}
