import React, { Component } from "react";
import { Button, Header, Form, Grid, Input, Icon } from "semantic-ui-react";
import ErrorToast from "./ErrorToast";
import { DateInput } from 'semantic-ui-calendar-react';
import moment from "moment";
import axios from 'axios';

export default class Handling extends Component {
  state = {
    product: {},
    warehouses: [],
    selectedWarehouse: -1,
    shelfLife: undefined,
    quantity: 0
  };

  loadProduct = async function(productId) {
    const response = await axios.get("/api/prodotti/" + productId);
    const product = response.data;
    this.setState({
      product: product
    });
  };

  loadWarehouses = async function() {
    const response = await axios.get("/api/magazzini/");
    const warehouses = response.data;
    this.setState({
      warehouses: warehouses
    });
  };

  componentDidMount = function() {
    this.loadProduct(this.props.match.params.productId);
    this.loadWarehouses();
  };

  render() {
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2">
              <Icon className="left floated" name='plug'/>
              Carico
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h3" content={this.state.product.NomeProdotto} />
            <span>{this.state.product.Marca}</span>
            <br />
            <span>{this.state.product.Confezione}</span>
            <span> </span>
            <span>{this.state.product.Quantita}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form>
              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="A"
                  options={this.state.warehouses.map(function(obj) {
                    return {
                      key: obj.ID,
                      value: obj.ID,
                      text: obj.Nome
                    };
                  })}
                  placeholder="Magazzino Destinazione"
                  onChange={(ev, data) => {
                    this.setState({
                      selectedWarehouse: data.value
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={6}
            computer={6}
            className="center aligned"
          >
          <DateInput
            inline
            value={this.state.shelfLife || ""}
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
            startMode="year"
            onChange={(event, {name, value}) => {
              this.setState({
                shelfLife: value
              });
            }}
          />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            className="center aligned"
          >
            <Button
              floated="left"
              negative
              onClick={() => {
                this.setState({
                  quantity: Math.max(this.state.quantity - 1, 0)
                });
              }}
            >
              -
            </Button>
            <Button
              floated="right"
              positive
              onClick={() => {
                this.setState({ quantity: this.state.quantity + 1 });
              }}
            >
              +
            </Button>
            <Input
              fluid
              value={this.state.quantity}
              onChange={(ev, data) => {
                this.setState({
                  quantity: isNaN(parseInt(data.value))
                    ? 0
                    : Math.max(parseInt(data.value), 0)
                });
              }}
              type="number"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="right aligned"
          >
            <Button
              className="floated"
              disabled={
                !(
                  !isNaN(new Date(this.state.shelfLife)) &&
                  this.state.selectedWarehouse >= 0 &&
                  this.state.quantity > 0
                )
              }
              onClick={async () => {
                try {
                  await axios.request(
                    "/api/prodotti/" +
                      this.state.product.IdProdotto +
                      "/giacenza/" +
                      this.state.selectedWarehouse +
                      "/carico",
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      data: JSON.stringify({
                        DataScadenza: this.state.shelfLife,
                        Quantita: this.state.quantity
                      })
                    }
                  );
                  this.props.history.goBack();
                } catch (e) {
                  ErrorToast("Impossibile eseguire l'operazione", e);
                }
              }}
            >
              OK
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
