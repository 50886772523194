import React, {Component} from "react";
import {
  Modal,
  Button,
  Icon
} from "semantic-ui-react";
import ErrorToast from "./ErrorToast";
import axios from 'axios';

export default class MasterDataEditor extends Component {

  state = {
    open: false,
    isEdit: false,
    data: {},
  }

  triggerOnClose = function (updated, element) {
    if (typeof this.props.onClose === 'function'){
      this.props.onClose(updated, element)
    }
  }

  render() {
    return (
      <Modal
        open={this.state.open}
        trigger={this.props.trigger}
        className="fixModal"
        onOpen={() => {

          const data = this.props.data;

          this.setState({
            open: true,
            isEdit: data !== undefined && data !== null,
            data: data || {}
          });

        }}
      >
        <Modal.Header>
          {this.state.isEdit ? "Modifica " + this.props.object : "Aggiungi " + this.props.object}
        </Modal.Header>
        <Modal.Content>
        {typeof this.props.form === "function" ? this.props.form.bind(this)() : undefined}
        </Modal.Content>
        <Modal.Actions>
          {this.state.isEdit ?
            <Button icon className="left floated" style={{marginLeft: 0}}
              onClick={async function() {
                try {
                  await axios.request(
                    this.props.api + "/" + this.state.data.ID,
                    {
                      method: "DELETE"
                    }
                  );
                  this.setState({
                    open: false
                  });
                  this.triggerOnClose(true);
                } catch (e) {
                  ErrorToast(
                    "Impossibile Eliminare " + this.props.object,
                    e
                  );
                }
              }.bind(this)}>
              <Icon name='trash alternate' />
              Elimina
            </Button> : undefined}
          <Button
            negative
            onClick={function() {
              this.setState({
                open: false
              });
              this.triggerOnClose(false);
            }.bind(this)}
          >
            <Icon name='cancel' />
            Annulla
          </Button>
          <Button
            positive
            onClick={async function() {
              try {
                const response = await axios.request(
                  this.props.api + "/" + (this.state.isEdit ? this.state.data.ID : ""),
                  {
                    method: this.state.isEdit ? "PUT" : "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    data: JSON.stringify(this.state.data)
                  }
                );
                const body = response.data;
                this.setState({
                  open: false
                });
                this.triggerOnClose(true, body);
              } catch (e) {
                ErrorToast(
                  "Impossibile " +
                    (this.state.isEdit ? "Modificare" : "Aggiungere") +
                    " " + this.props.object,
                  e
                );
              }
            }.bind(this)}
          >
            <Icon name='save outline' />
            Salva
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
