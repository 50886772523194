import React, { Component } from 'react';
import {
  Grid,
  Header,
  Table,
  Icon,
  Loader,
  Label
} from "semantic-ui-react";
import { Media } from "./Media"
import { Link } from "react-router-dom";
import axios from 'axios';

export default class ReportReorder extends Component {

  state = {
    response: [],
    queryInProgress: true,
    queryInError: false
  };

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({
        response: res,
        queryInProgress: false,
        queryInError: false
      }))
      .catch(err => {
        this.setState({
          queryInProgress: false,
          queryInError: true
        });
      });
  }

  callApi = async function () {
    const response = await axios.get('/api/ReportReorder');
    const body = response.data;

    return body;
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content="Riordino" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            {this.state.queryInProgress ? (
              <Loader active inline="centered" />
            ) : this.state.queryInError ? (
              <Label color='red'>Error</Label>
            ) : (this.state.response.length > 0 ? (
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Categoria</Table.HeaderCell>
                    <Table.HeaderCell>Giacenza</Table.HeaderCell>
                    <Table.HeaderCell>Quantità Minima</Table.HeaderCell>
                    <Table.HeaderCell>Quantità Ottimale</Table.HeaderCell>
                    <Table.HeaderCell>Quantità Massima</Table.HeaderCell>
                    <Table.HeaderCell>Prezzo Medio</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.response.map((row, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {row.TipoRiordino === 'MIN' ? <Icon name='warning circle' color='red' /> : <Icon name='check circle' color='green' />}
                          <Link to={'/products?category=' + row.ID}>
                            {row.NomeCompleto.replace(new RegExp('-->', 'g'), '→')}
                          </Link>
                        </Table.Cell>
                        <Table.Cell singleLine textAlign='center'>
                          <Media as="span" at="sm"><b>Giacenza: </b></Media>{
                            (row.GiacenzaCategoriaUnitaCorrente < 0.01 ?
                              parseFloat(row.GiacenzaCategoriaUnitaCorrente) :
                              Math.round((row.GiacenzaCategoriaUnitaCorrente + Number.EPSILON) * 100) / 100) +
                            ' ' + row.UnitaDiMisuraNome}</Table.Cell>
                        <Table.Cell singleLine textAlign='center' style={row.QuantitaMinima ? undefined : {height:0}}>
                          <Media as="span" at="sm">{row.QuantitaMinima ? <b>Quantità Minima: </b> : undefined}</Media>
                          {row.QuantitaMinima ? (row.QuantitaMinima + ' ' + row.UnitaDiMisuraNome) : undefined}
                        </Table.Cell>
                        <Table.Cell singleLine textAlign='center' style={row.QuantitaOttimale ? undefined : {height:0}}>
                          <Media as="span" at="sm">{row.QuantitaOttimale ? <b>Quantità Ottimale: </b> : undefined}</Media>
                          {row.QuantitaOttimale ? (row.QuantitaOttimale + ' ' + row.UnitaDiMisuraNome) : undefined}
                        </Table.Cell>
                        <Table.Cell singleLine textAlign='center' style={row.QuantitaMassima ? undefined : {height:0}}>
                          <Media as="span" at="sm">{row.QuantitaMassima ? <b>Quantità Massima: </b> : undefined}</Media>
                          {row.QuantitaMassima ? (row.QuantitaMassima + ' ' + row.UnitaDiMisuraNome) : undefined}
                        </Table.Cell>
                        <Table.Cell singleLine textAlign='center' style={row.PrezzoMedioPesatoUnitaPrincipale ? undefined : {height:0}}>
                          <Media as="span" at="sm">{row.PrezzoMedioPesatoUnitaPrincipale ? <b>Prezzo: </b> : undefined}</Media>
                          {row.PrezzoMedioPesatoUnitaPrincipale ? ('~ ' + row.PrezzoMedioPesatoUnitaPrincipale + '€ / ' + row.UnitaDiMisuraPrincipaleNome) : undefined}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            ) : (
                <Label>No data</Label>
              ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
