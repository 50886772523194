//APP container, ovvero la videata principale dell'applicazione dopo il logon
// che viene gestito in Auth.js
import React, {
  Component
} from "react";
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";
import {
  SemanticToastContainer
} from "react-semantic-toasts";
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { MediaContextProvider } from "./Media"
import "./App.css";
import NavBar from "./NavBar";
import Home from "./Home";
import CategoryEditor from "./CategoryEditor";
import Products from "./Products";
import ProductsEditor from "./ProductsEditor";
import Prices from "./Prices";
import Stores from "./Stores";
import Brands from "./Brands";
import StorageLocations from "./StorageLocations";
import Packs from "./Packs";
import Handling from "./Handling";
import Loading from "./Loading";
import ReportShelfLife from "./ReportShelfLife";
import ReportReorder from "./ReportReorder";
import {
  UserData
} from 'react-oidc'

class App extends Component {
  routes = [{
      //Chiama Schermata Iniziale Home "Magazzino"
      path: "/",
      exact: true,
      main: Home
    },
    {
      //Chiama Menu Categorie
      path: "/category-editor",
      exact: true,
      main: CategoryEditor
    },
    {
      //Chiama Menu Giacenze
      path: "/products/:productId",
      exact: true,
      main: ProductsEditor
    },
    {
      //Chiama Menu Giacenze
      path: "/products",
      exact: true,
      main: Products
    },
    {
      //Chiama Menu Giacenze
      path: "/prices/:productId",
      exact: true,
      main: Prices
    },
    {
      //Chiama Menu Negozi
      path: "/stores",
      exact: true,
      main: Stores
    },
    {
      //Chiama Menu Marche
      path: "/brands",
      exact: true,
      main: Brands
    },
    {
      //Chiama Menu Magazzini
      path: "/storagelocations",
      exact: true,
      main: StorageLocations
    },
    {
      //Chiama Menu Confezioni
      path: "/packs",
      exact: true,
      main: Packs
    },
    {
      //Gestisce Trasferimento di un prodotto tra due magazzini
      path: "/handling/:productId",
      exact: true,
      main: Handling
    },
    {
      //Gestisce Carico di un prodott in un magazzino
      path: "/loading/:productId",
      exact: true,
      main: Loading
    },
    {
      //Gestisce Scarico di un prodotto da un magazzino
      path: "/unloading/:productId",
      exact: true,
      main: Handling
    },
    {
      //Lancia Report prodotti in scadenza
      path: "/report/shelf-life",
      exact: true,
      main: ReportShelfLife
    },
    {
      //Lancia Report prodotti in scadenza
      path: "/report/reorder",
      exact: true,
      main: ReportReorder
    }
  ];

  render() {

    const leftItems = [{
        as: Link,
        to: "/products",
        content: "Prodotti",
        key: "stock"
      },
      {
        as: Link,
        to: "/category-editor",
        content: "Categorie",
        key: "category"
      },
      {
        as: Link,
        to: "/stores",
        content: "Negozi",
        key: "stores"
      },
      {
        as: Link,
        to: "/brands",
        content: "Marche",
        key: "brands"
      },
      {
        as: Link,
        to: "/storagelocations",
        content: "Magazzini",
        key: "storagelocations"
      },
      {
        as: Link,
        to: "/packs",
        content: "Confezioni",
        key: "packs"
      }
    ];
    const rightItems = [];

    return (
      <div>
        <MediaContextProvider className="fillheight">
          <SemanticToastContainer position = "bottom-left" className="error-toast"/>
          <NavBar
            leftItems = {
              leftItems
            }
            rightItems = {
              rightItems
            } >
            <div
              className = "fillheightNoNavbar"
              style = {{ marginTop: "54px" }}>
                {
                  this.routes.map((route, index) => (
                    <Route
                      key = {index}
                      path = {route.path}
                      exact = {route.exact}
                      component = {route.main}
                    />
                  ))
                }
            </div>
          </NavBar>
        </MediaContextProvider>
      </div>
    );
  }
}
export default App;
App.contextType = UserData;
