import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Auth from './Auth';
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';

async function startApp() {

  const oidcConfig = Object.assign({
    redirect_uri: window.location.protocol + '//' + window.location.host + '/login/callback'
  }, await (await fetch('/oidc/config')).json());

  ReactDOM.render(<Auth oidcConfig={oidcConfig}/>, document.getElementById('root'));

}

registerServiceWorker();

// Wait service worker to be ready when starting the app in production
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator){
  navigator.serviceWorker.ready.then(startApp);
} else {
  startApp();
}
