// Gestione MAGAZZINI
import React, {Component} from "react";
import {
  Accordion,
  Item,
  Form
} from "semantic-ui-react";
import MasterDataList from "./MasterDataList";

const StorageLocationItem = function (item, editorButton) {
  return (
      <div key={item.ID}>
      <Accordion.Title
        index={item.ID}
      >
        <Item.Group>
          <Item style={{margin: "0px"}}>
            <Item.Content style={{padding: "0px"}}>
                {editorButton}
              <Item.Header>
                {item.Nome}
              </Item.Header>
              <Item.Meta>
                <span>{item.Descrizione}</span>
              </Item.Meta>
              <Item.Description>
                <span>{item.Area}</span>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Accordion.Title>
    </div>
  );
}

const StorageLocationForm = function () {
  return (<Form>
    <Form.Field>
      <label>Nome</label>
      <Form.Input
        placeholder="Nome"
        value={this.state.data.Nome || ""}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, Nome: value}
          })
        }
      />
    </Form.Field>
    <Form.Field>
      <label>Descrizione</label>
      <Form.Input
        placeholder="Descrizione"
        value={this.state.data.Descrizione || ""}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, Descrizione: value}
          })
        }
      />
    </Form.Field>
    <Form.Field>
      <label>Area</label>
      <Form.Input
        placeholder="Area"
        value={this.state.data.Area || ""}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, Area: value}
          })
        }
      />
    </Form.Field>
  </Form>)
}

export default class StorageLocations extends Component {

  render() {
    return (
      <MasterDataList
        title="Magazzini"
        api="/api/magazzini"
        searchPlaceholder="Cerca Magazzini ..."
        accordionItem={StorageLocationItem}
        form={StorageLocationForm}
        object="Magazzini"
      />
    );
  }
}
