import React, { Component } from "react";
import { Button, Modal, Form, Label, Icon, Loader, Segment, Dimmer, Input, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ErrorToast from "./ErrorToast";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import axios from 'axios';

export default class CategoryEditor extends Component {
  state = {
    treeData: [],
    loading: true,
    error: false,
    flattenedData: {},
    unitaMisuraData: [],
    popupOpenId: -1,
    popupData: {
      nome: "",
      descrizione: ""
    }
  };

  mapTree = function(tree, mappingFunction) {
    tree.forEach(
      function(value, index, array) {
        if (value.children && value.children.length > 0) {
          this.mapTree(value.children, mappingFunction);
        }
        tree[index] = mappingFunction(value);
      }.bind(this)
    );
  };

  reduceTree = function (acc, tree) {
    tree.forEach(function (obj) {
      acc[obj.ID] = Object.assign({}, obj, {children:undefined});
      if (Array.isArray(obj.children)){
        this.reduceTree(acc, obj.children);
      }
    }.bind(this))
  }

  loadTree = async function() {
    this.setState({
      loading: true
    });
    try {

      const before = {};
      this.reduceTree(before, this.state.treeData);

      const response = await axios.get("/api/categorie?format=tree");
      const body = response.data;

      this.mapTree(body, function(obj) {
        obj.title = obj.Nome;
        obj.subtitle = obj.Descrizione;
        obj.expanded = (before[obj.ID] || {}).expanded || false;
        return obj;
      });
      const flattenedData = {};
      this.reduceTree(flattenedData, body)
      this.setState({
        treeData: body,
        flattenedData: flattenedData,
        loading: false,
        error: false
      });
    } catch (e) {
      console.error(e);
      this.setState({
        error: true,
        loading: false
      });
    }
  };

  loadUnitaMisura = async function() {
    const response = await axios.get("/api/unita_misura");
    const body = response.data;

    this.setState({
      unitaMisuraData: body
    });
  };

  onMoveNode = async function(ev) {
    try {
      const parentId = ev.nextParentNode != null ? ev.nextParentNode.ID : null;
      await axios.request("/api/categorie/" + ev.node.ID, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          Nome: ev.node.Nome,
          Descrizione: ev.node.Descrizione,
          Parent_ID: parentId,
          QuantitaMinima: ev.node.QuantitaMinima,
          QuantitaOttimale: ev.node.QuantitaOttimale,
          QuantitaMassima: ev.node.QuantitaMassima,
          UnitaDiMisura: ev.node.UnitaDiMisura
        })
      });
    } catch (e) {
      ErrorToast("Impossibile Spostare Categoria", e);
    } finally {
      this.loadTree();
    }
  }.bind(this);

  editCategoryModal = function(button, currentNode, isEdit) {
    const popupId =
      (isEdit ? "E-" : "A-") + (currentNode ? currentNode.ID : "ROOT");
    const parentNode = isEdit ? (currentNode.Parent_ID ? this.state.flattenedData[currentNode.Parent_ID] : undefined) : currentNode;
    return (
      <Modal
        open={this.state.popupOpenId === popupId}
        trigger={button}
        className="fixModal"
        onOpen={function() {
          if (isEdit) {
            this.setState({
              popupData: {
                nome: currentNode.Nome,
                descrizione: currentNode.Descrizione,
                quantitaMinima: currentNode.QuantitaMinima
                  ? currentNode.QuantitaMinima
                  : 0,
                quantitaOttimale: currentNode.QuantitaOttimale
                  ? currentNode.QuantitaOttimale
                  : 0,
                quantitaMassima: currentNode.QuantitaMassima
                  ? currentNode.QuantitaMassima
                  : 0,
                unitaDiMisura: currentNode.UnitaDiMisura
              }
            });
          } else {
            this.setState({
              popupData: {
                nome: "",
                descrizione: "",
                quantitaMinima: 0,
                quantitaOttimale: 0,
                quantitaMassima: 0,
                unitaDiMisura: 0
              }
            });
          }
          this.setState({
            popupOpenId: popupId
          });
        }.bind(this)}
      >
        <Modal.Header>
          {isEdit ? "Modifica Categoria" : "Aggiungi Categoria"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label> Nome </label>
              <Form.Input
                placeholder="Nome"
                value={this.state.popupData.nome}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: { ...this.state.popupData, nome: value }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label> Descrizione </label>
              <Form.Input
                placeholder="Descrizione"
                value={this.state.popupData.descrizione}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: { ...this.state.popupData, descrizione: value }
                  })
                }
              />
            </Form.Field>
            <Form.Group>
              <Form.Select
                width={4}
                label="Unità di Misura"
                value={this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined)}
                options= {[{key: null, value: null, text: "Nessuna"}].concat(
                  this.state.unitaMisuraData.filter(function (obj) {
                  return !parentNode || !parentNode.UnitaDiMisura ||
                    obj.PrincipaleID === this.state.unitaMisuraData.filter(function (obj2) {
                      return obj2.ID === parentNode.UnitaDiMisura
                    })[0].PrincipaleID;
                }.bind(this)).map(function (obj) {
                  return {key: obj.ID, value: obj.ID, text: obj.Nome};
                }))}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: value !== null ? {
                      ...this.state.popupData,
                      unitaDiMisura: value
                    } : {
                      ...this.state.popupData,
                      unitaDiMisura: value,
                      quantitaMinima: 0,
                      quantitaOttimale: 0,
                      quantitaMassima: 0
                    }
                  })
                }
              />
              <Form.Input
                width={3}
                disabled={!(this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined))}
                type="number"
                min="0"
                label="Quantità Minima"
                value={this.state.popupData.quantitaMinima}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: {
                      ...this.state.popupData,
                      quantitaMinima: value
                    }
                  })
                }
              />
              <Form.Input
                width={3}
                disabled={!(this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined))}
                type="number"
                min="0"
                label="Quantità Ottimale"
                value={this.state.popupData.quantitaOttimale}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: {
                      ...this.state.popupData,
                      quantitaOttimale: value
                    }
                  })
                }
              />
              <Form.Input
                width={3}
                disabled={!(this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined))}
                type="number"
                min="0"
                label="Quantità Massima"
                value={this.state.popupData.quantitaMassima}
                onChange={(e, { value }) =>
                  this.setState({
                    popupData: {
                      ...this.state.popupData,
                      quantitaMassima: value
                    }
                  })
                }
              />
              <Form.Button
                width={2}
                disabled={!(this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined))}
                label="Azzera Quantità"
                icon="remove"
                onClick={function() {
                  this.setState({
                    popupData: {
                      ...this.state.popupData,
                      quantitaMinima: 0,
                      quantitaOttimale: 0,
                      quantitaMassima: 0
                    }
                  });
                }.bind(this)}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative={true}
            onClick={function() {
              this.setState({
                popupOpenId: undefined
              });
            }.bind(this)}
          >
            Annulla
          </Button>
          <Button
            positive={true}
            onClick={async function() {
              try {
                await axios.request(
                  "/api/categorie/" + (isEdit ? currentNode.ID : ""),
                  {
                    method: isEdit ? "PUT" : "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    data: JSON.stringify({
                      Nome: this.state.popupData.nome,
                      Descrizione: this.state.popupData.descrizione,
                      Parent_ID:
                        currentNode === null
                          ? null
                          : isEdit ? currentNode.Parent_ID : currentNode.ID,
                      QuantitaMinima:
                        parseInt(this.state.popupData.quantitaMinima) === 0 || !this.state.popupData.unitaDiMisura
                          ? null
                          : parseInt(this.state.popupData.quantitaMinima),
                      QuantitaOttimale:
                        parseInt(this.state.popupData.quantitaOttimale) === 0 || !this.state.popupData.unitaDiMisura
                          ? null
                          : parseInt(this.state.popupData.quantitaOttimale),
                      QuantitaMassima:
                        parseInt(this.state.popupData.quantitaMassima) === 0 || !this.state.popupData.unitaDiMisura
                          ? null
                          : parseInt(this.state.popupData.quantitaMassima),
                      UnitaDiMisura: this.state.popupData.unitaDiMisura || (parentNode ? parentNode.UnitaDiMisura : undefined)
                    })
                  }
                );
                this.setState({
                  popupOpenId: undefined
                });
                this.loadTree();
              } catch (e) {
                ErrorToast(
                  "Impossibile " +
                    (isEdit ? "Modificare" : "Aggiungere") +
                    " Categoria",
                  e
                );
              }
            }.bind(this)}
          >
            Salva
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  componentDidMount() {
    this.loadUnitaMisura()
      .then(this.loadTree.bind(this))
      .catch(err => {
        this.setState({
          loading: false,
          error: true
        })
      });
  }

  render() {
    const { searchString, searchFocusIndex, searchFoundCount } = this.state;

    // Case insensitive search of `node.title`
    const customSearchMethod = ({ node, searchQuery }) =>
      searchQuery &&
      (node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
        node.subtitle.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);

    const selectPrevMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1
      });

    const selectNextMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0
      });

    return (
      <Grid className="categoryFillHeight">
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content="Categorie" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="noPaddingBottom">
          <Grid.Column mobile={16} tablet={8} computer={8} className="bottomSpace">
                <Input
                  id="find-box"
                  type="text"
                  fluid
                  placeholder='Cerca Categorie ...'
                  onChange={event =>
                    this.setState({
                      searchString: event.target.value
                    })
                  }
                />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={4} computer={4} className="bottomSpace">
                <Button.Group
                fluid
                >
                  <Button
                    icon='left chevron'
                    disabled={!searchFoundCount}
                    onClick={selectPrevMatch}
                  />
                  <Button
                    disabled
                    content={
                      (searchFoundCount > 0 ? searchFocusIndex + 1 : 0) +
                      ' / ' +
                      (searchFoundCount || 0)
                    }
                  />
                  <Button
                    icon='right chevron'
                    disabled={!searchFoundCount}
                    onClick={selectNextMatch}
                  />
                </Button.Group>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={4} computer={4} className="bottomSpace">
              {this.editCategoryModal(
                <Button
                fluid
                > Aggiungi Radice </Button>,
                null,
                false
              )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="fillheightNoNavbar noPaddingBottom">
          <Grid.Column className="fillheight">
            <Dimmer.Dimmable as={Segment}  className="fillheight removeDimmerBorders">
              <Dimmer inverted active={this.state.loading}><Loader /></Dimmer>
              { this.state.error ? <Label color='red'>Error</Label> : ""}
              <SortableTree
              treeData={this.state.treeData}
              onChange={treeData =>
                this.setState({
                  treeData
                })
              }
              onlyExpandSearchedNodes
              onMoveNode={this.onMoveNode}
              searchMethod={customSearchMethod}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={matches =>
                this.setState({
                  searchFoundCount: matches.length,
                  searchFocusIndex:
                  matches.length > 0 ? searchFocusIndex % matches.length : 0
                })
              }
              canNodeHaveChildren={node => {
                return node.CountProdottiDiretti === 0;
              }}
              generateNodeProps={({ node, path }) => ({
                buttons: [
                  parseInt(node.CountProdottiDiretti) === 0
                  ? this.editCategoryModal(<Button icon="add" />, node, false)
                  : undefined,
                  (node.children.length === 0) &
                  (parseInt(node.CountProdottiDiretti) === 0) ? (
                    <Button
                    icon="trash"
                    onClick={async function() {
                      try {
                        await axios.delete("/api/categorie/" + node.ID);
                      } catch (e) {
                        ErrorToast("Impossibile Eliminare Categoria", e);
                      } finally {
                        this.loadTree();
                      }
                    }.bind(this)}
                    />
                  ) : (
                    undefined
                  ),
                  this.editCategoryModal(<Button icon="edit" />, node, true)
                ]
                .concat(
                  node.UnitaDiMisura
                  ? [
                    <Label
                    circular
                    className="categoryLabel"
                    color={node.QuantitaMinima ? "red" : undefined}
                    >
                    {node.QuantitaMinima}
                    </Label>,
                    <Label
                    circular
                    className="categoryLabel"
                    color={node.QuantitaOttimale ? "green" : undefined}
                    >
                    {node.QuantitaOttimale}
                    </Label>,
                    <Label
                    circular
                    className="categoryLabel"
                    color={node.QuantitaMassima ? "yellow" : undefined}
                    >
                    {node.QuantitaMassima}
                    </Label>,
                    <Label basic className="categoryLabel" color={"blue"}>
                    {node.UnitaDiMisuraNome}
                    </Label>,
                    <Label className="categoryLabel">
                    Giacenza
                    <Label.Detail>{node.GiacenzaCategoriaUnitaCorrente < 0.01 ?
                      parseFloat(node.GiacenzaCategoriaUnitaCorrente) :
                      Math.round((node.GiacenzaCategoriaUnitaCorrente + Number.EPSILON ) * 100) / 100}</Label.Detail>
                      </Label>
                    ]
                    : []
                  )
                  .concat([
                    <Label className="categoryLabel" as={Link} to={'/products?category=' + node.ID}>
                    <Icon name='mail forward' />
                    Prodotti
                    <Label.Detail>{node.CountProdotti}</Label.Detail>
                    </Label>
                  ]).concat(node.PrezzoMedioPesatoUnitaPrincipale && node.UnitaDiMisuraPrincipaleNome? [
                    <Label className="categoryLabel">
                    Prezzo
                    <Label.Detail>~ {node.PrezzoMedioPesatoUnitaPrincipale} € / {node.UnitaDiMisuraPrincipaleNome}</Label.Detail>
                    </Label>
                  ] : [])
                })}
                />
            </Dimmer.Dimmable>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
