import { toast } from "react-semantic-toasts";

export default function (title, error) {
  let description = "";
  if (typeof error.response === "object" && typeof error.response.data === "object" && typeof error.response.data.error === "string"){
    description = error.response.data.error;
  } else if (typeof error.response === "object" && typeof error.response.data === "object" && typeof error.response.data.code === "string"){
    description = error.response.data.code;
  } else if (typeof error.response === "object" && typeof error.response.data === "string" && error.response.data.length > 0) {
    description = error.response.data;
  } else if (typeof error === "object"  && typeof error.message === "string"){
    description = error.message;
  } else if (typeof error === "string") {
    description = error;
  }
  toast({
    type: "error",
    time: 10000,
    icon: "exclamation triangle",
    title: title,
    description: description
  });
}
