// Gestione MARCHE
import React, {Component} from "react";
import {
  Accordion,
  Item,
  Form
} from "semantic-ui-react";
import MasterDataList from "./MasterDataList";

const BrandItem = function (item, editorButton) {
  return (
      <div key={item.ID}>
      <Accordion.Title
        index={item.ID}
      >
        <Item.Group>
          <Item style={{margin: "0px"}}>
            <Item.Content style={{padding: "0px"}}>
                {editorButton}
              <Item.Header>
                {item.Nome}
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Accordion.Title>
    </div>
  );
}

export const BrandForm = function () {
  return (<Form>
    <Form.Field>
      <label> Nome </label>
      <Form.Input
        placeholder="Nome"
        value={this.state.data.Nome || ""}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, Nome: value }
          })
        }
      />
    </Form.Field>
  </Form>)
}

export default class Brands extends Component {


  render() {

    return (
      <MasterDataList
        title="Marche"
        api="/api/marche"
        searchPlaceholder="Cerca Marche ..."
        accordionItem={BrandItem}
        form={BrandForm}
        object="Marca"
      />
    );
  }
}
