import React from "react";
import {
  Accordion,
  Icon,
  Input,
  Label,
  Button,
  Grid,
  Loader,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReactQueryParams from "react-query-params";
import MasterDataEditor from "./MasterDataEditor";
import axios from 'axios';

const limit = 30;
const minSearchSize = 0;

export default class MasterDataList extends ReactQueryParams {
  state = {
    activeItemId: -1,
    searchInProgress: false,
    searchInError: false,
    searchText: "",
    items: [],
    offset: 0
  };

  timeoutHandler;

  // chiama l'API che è definita nel file server.js per leggere l'anagrafica dei  prodotti
  loadItems = async function(search, paginationOp) {
    try {

      let offset = this.state.offset;
      if (paginationOp === "NEXT"){
        offset = offset + limit;
      } else if (paginationOp === "PREV") {
        offset = offset - limit;
      } else if (paginationOp === "RESET") {
        offset = 0;
      }

      this.setState({
        searchInProgress: true,
        searchInError: false
      });

      // let definisce la variabile url
      let url = this.props.api +
        "?limit=" + limit +
        "&offset=" + offset +
        "&search=" + search;

      /* definisce la costante response assegnandogli il risultato della funzione axios.get
        (la funzione axios.get restituisce molti campi tra cui body) */
      const response = await axios.get(url);
      // metto nella costante body il risultato del campo body restituito dalla axios.get formato json
      const body = response.data;

      this.setState({
        items: body,
        searchInProgress: false,
        searchInError: false,
        offset: offset
      });

    } catch (e) {

      this.setState({
        items: [],
        searchInProgress: false,
        searchInError: true
      });

    }
  };

  performSearch = function(searchText, paginationOp) {
    searchText = searchText || "";
    if (searchText !== this.state.searchText) {
        paginationOp = "RESET";
    }
    this.setState({
      searchText: searchText
    });
    this.setQueryParams({
      search: searchText.length >= minSearchSize ? searchText : undefined
    });
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.setState({
        searchInProgress: false
      });
    }
    // lancio la ricerca solo se nel campo search ho scritto almeno 3 caratteri
    if (searchText.length >= minSearchSize) {
      this.setState({
        searchInProgress: true,
        activeItemId: -1
      });
      if (searchText.length < minSearchSize){
        this.loadItems("", paginationOp);
      } else {
        /* chiamo la funzione di ricerca dopo 500 msec dall'ultimo
        carattere scritto, è una ottimizzazione per non sovraccaricare
        il server di troppe ricerche mentre sto scrivendo la stringa da
        ricercare */
        this.timeoutHandler = setTimeout(
          function() {
            this.loadItems(searchText, paginationOp);
          }.bind(this),
          500
        );
      }
    } else {
      // se ho scritto meno di 3 caratteri imposto l'array dei prodotti da restituire a vuoto
      this.setState({
        items: []
      });
    }
  };

  refreshData = function (){
    this.loadItems(this.state.searchText);
  }

  /* componentDidMount è la funzione che viene chiamata all'apertura della pagina */
  /* Al suo interno metto le funzioni da eseguire */
  componentDidMount = function() {

    if (this.queryParams && this.queryParams.search) {
      this.performSearch(decodeURI(this.queryParams.search || ""));
    } else {
      this.performSearch();
    }
    // usata per ricordare il codice del prodotto selezionato per riposizionarsi
    // quando si ritorna alla pagina
    // if (this.queryParams && this.queryParams.activeProduct) {
    //   this.openProduct(parseInt(this.queryParams.activeProduct));
    // }
  };

  render() {

    const plusButton = (
      <Button icon className="right floated" style={{height: "100%"}}>
        <Icon name="plus" />
      </Button>
    );

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content={this.props.title} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {/* Inizio definizione Colonna 1 - Seleziona il prodotto da cercare */}
          <Grid.Column mobile={16} tablet={16} computer={16} className="bottomSpace">

            {this.props.newItemPath ?
              <Link to={this.props.newItemPath}>
                {plusButton}
              </Link>
               :
              <MasterDataEditor
                trigger={plusButton}
                onClose={(updated) => {
                  if (updated){
                    this.refreshData();
                  }
                }}
                form={this.props.form}
                api={this.props.api}
                object={this.props.object}
              />
            }

            <Input
              value={this.state.searchText}
              placeholder={this.props.searchPlaceholder}
              fluid
              labelPosition="right"
              type="text"
              onChange={(ev, data) => {
                this.performSearch(data.value);
              }}
            >
              <Label icon="search" />
              <input />
              <Label
                basic
                icon="remove"
                onClick={() => {
                  this.performSearch();
                }}
              />
            </Input>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            {(this.state.searchInProgress) ? (
              <Loader active inline="centered" />
            ) : (this.state.searchInError) ? (
              <Label color='red'>Error</Label>
            ) : (this.state.items.length > 0 ? (
              <Accordion styled fluid>
                {this.state.items.map(item => {
                  return this.props.accordionItem(item, (
                    <MasterDataEditor
                      trigger={
                        <Button icon className="right floated">
                          <Icon name="edit" />
                        </Button>
                        }
                      form={this.props.form}
                      api={this.props.api}
                      object={this.props.object}
                      data={item}
                      onClose={(updated) => {
                        if (updated){
                          this.refreshData();
                        }
                      }}
                    />
                  ));
                })}
              </Accordion>
            ) : (
              <Label>No data</Label>
            ))}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
            {
              this.state.offset !== 0 ?
                <Button
                    icon
                    onClick={()=>{
                        this.performSearch(this.state.searchText, "PREV");
                        }}
                >
                  <Icon name="angle left" />
                  Prev
                </Button>
              : undefined
            }
            {
              this.state.items.length === limit ?
                <Button
                    icon
                    onClick={()=>{
                        this.performSearch(this.state.searchText, "NEXT");
                        }}
                >
                  Next
                  <Icon name="angle right" />
                </Button>
              : undefined
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
