import React, { Component } from "react";
import {
  Modal,
  Checkbox,
  Label
} from "semantic-ui-react";
import Quagga from "quagga";

export default class EanScanner extends Component {

  state = {
    reader: "ean_reader",
    isOpen: false
  };

  results = []

  _onDetected(result) {
    if (this.state.isOpen){
      const code = ((result || {}).codeResult || {}).code;
      console.log("detected-new", code);
      this.results.push(code);
      const count = this.results.filter(function (c) {
        return c === code;
      }).length;
      if(count >= 2) {
        if(typeof this.props.onDetected === 'function') {
          this.props.onDetected(code);
        }
        this._close();
      }
    }
  }

  _init(reader){
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          target: "#eanscanner"
        },
        numOfWorkers: navigator.hardwareConcurrency,
        decoder: {
          readers: [reader]
        },
        locate: false
      },
      function(err) {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(this._onDetected);
  }

  _stop(){
    Quagga.stop();
  }

  _close(){
    console.log("close");
    this.setState({isOpen: false});
    this._stop();
  }

  _changedReader(reader){
    this.setState({
      reader: reader
    });
    this._stop();
    this._init(reader)
  }

  render() {
    return (
      <Modal
        open={this.state.isOpen}
        trigger={this.props.trigger}
        closeIcon
        size="tiny"
        className="eanscannermodal"
        onClose={this._close}
        onOpen={() => {
          this._onDetected = this._onDetected.bind(this);
          this._close = this._close.bind(this);
          this.results = [];
          this.setState({
            isOpen: true,
          });
        }}
        onMount={() => {
          if (
            navigator.mediaDevices &&
            typeof navigator.mediaDevices.getUserMedia === "function"
          ) {
            this._init(this.state.reader);
          }
        }}
      >
        <Modal.Header>EAN Scanner</Modal.Header>
        <Modal.Content>
          <Modal.Description>
              <div className="eanSwitch">
                <Label
                  pointing='right'
                  color={this.state.reader === 'ean_8_reader' ? 'blue' : 'grey'}
                  onClick={()=>{
                    this._changedReader('ean_8_reader')
                  }}
                >
                  EAN 8
                </Label>
                <Checkbox
                  checked={this.state.reader === 'ean_reader'}
                  slider
                  onChange={(event, data) => {
                    this._changedReader(data.checked ? 'ean_reader' : 'ean_8_reader')
                  }}/>
                  <Label
                    pointing='left'
                    color={this.state.reader === 'ean_reader' ? 'blue' : 'grey'}
                    onClick={()=>{
                      this._changedReader('ean_reader')
                    }}
                  >
                    EAN 13
                  </Label>
              </div>
            <div id="eanscanner" />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
