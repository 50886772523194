import React from "react";
import {
  Button,
  Header,
  Form,
  Grid,
  Loader,
  Icon,
  Label
} from "semantic-ui-react";
import ErrorToast from "./ErrorToast";
import EanScanner from "./EanScanner"
import ReactQueryParams from "react-query-params";
import MasterDataDropdown from "./MasterDataDropdown";
import { BrandForm } from "./Brands";
import { PackForm } from "./Packs";
import axios from 'axios';

export default class ProductsEditor extends ReactQueryParams {
  state = {
    isEdit: false,
    isLoading: false,
    isError: false,
    data: {
      Nome: "",
      MarcaID: undefined,
      CategorieID: undefined,
      ConfezioniID: undefined,
      UnitaMisuraConfezioniID: undefined,
      QuantitaConfezione: 0,
      EAN: "",
      Descrizione: "",
      PezziMagazzino: 1
    }
  };

  loadProduct = async function (productId) {
    try {
      const response = await axios.get("/api/prodotti/" + productId);
      const product = response.data;
      this.setState({
        isLoading: false,
        isError: false,
        data: {
          Nome: product.NomeProdotto,
          MarcaID: product.MarcaID,
          CategorieID: product.IdCategoria,
          ConfezioniID: product.ConfezioniID,
          UnitaMisuraConfezioniID: product.UnitaMisuraConfezioniID,
          QuantitaConfezione: product.QuantitaConfezione,
          EAN: product.EAN,
          Descrizione: product.Descrizione,
          PezziMagazzino: product.PezziMagazzino
        }
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        isError: true
      });
    }
  }

  componentDidMount = function() {
    if (this.props.match.params.productId !== '$NEW'){
      this.setState({
        isEdit: true,
        isLoading: true
      });
      this.loadProduct(this.props.match.params.productId);
    } else if (this.queryParams.copyFrom) {
      this.setState({
        isEdit: false,
        isLoading: true
      });
      this.loadProduct(this.queryParams.copyFrom);
    }

  };

  render() {
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content={this.state.isEdit ? "Modifica Prodotto" : "Aggiungi Prodotto"} />
          </Grid.Column>
        </Grid.Row>
        {
          this.state.isLoading ?
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="center aligned"
            >
              <Loader active inline="centered" />
            </Grid.Column>
          </Grid.Row>
          :
            this.state.isError ?
              <Label color='red'>Error</Label>
              :
              <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Form>

                  <Form.Group widths="equal">
                    <Form.Input
                      placeholder="Descrizione Prodotto"
                      label="Nome"
                      value={this.state.data.Nome}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, Nome: value }
                        })
                      }
                    />
                    <Form.Input fluid style={{alignItems: "flex-end"}}>
                        <Form.Input
                          className="formWitButtonFill"
                          placeholder="EAN"
                          label="EAN"
                          value={this.state.data.EAN}
                          onChange={(e, { value }) =>
                            this.setState({
                              data: { ...this.state.data, EAN: value || undefined }
                            })
                          }
                        />
                        <EanScanner
                          trigger={
                              <Button
                              icon
                              style={{height: "38px", marginLeft: "3px"}}>
                              <Icon name="barcode" />
                            </Button>
                          }
                          onDetected={(code) => {
                            this.setState({
                              data: { ...this.state.data, EAN: code }
                            })
                          }}
                        />
                      </Form.Input>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Input
                      type="number"
                      placeholder="Quantità"
                      label="Quantità"
                      value={this.state.data.QuantitaConfezione}
                      onChange={(e, { value }) => {
                        const numericValue =
                          isNaN(parseFloat(value))
                          ? 0
                          : Math.max(parseFloat(value), 0)
                        this.setState({
                          data: { ...this.state.data, QuantitaConfezione: numericValue }
                        })
                      }}
                    />

                    <MasterDataDropdown
                      api="/api/unita_misura"
                      object="Unità di Misura"
                      startingValue={this.state.data.UnitaMisuraConfezioniID}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, UnitaMisuraConfezioniID: value }
                        })
                      }
                      />

                    <MasterDataDropdown
                      withNew
                      api="/api/marche"
                      object="Marca"
                      startingValue={this.state.data.MarcaID}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, MarcaID: value }
                        })
                      }
                      editorForm={BrandForm}
                      />

                  </Form.Group>


                  <Form.Group widths="equal">
                    <Form.Input
                      type="number"
                      placeholder="Pezzi Magazzino"
                      label="Pezzi Magazzino"
                      value={this.state.data.PezziMagazzino}
                      onChange={(e, { value }) => {
                        const numericValue =
                          isNaN(parseInt(value))
                          ? 0
                          : Math.max(parseInt(value), 0)
                        this.setState({
                          data: { ...this.state.data, PezziMagazzino: numericValue }
                        })
                      }}
                    />

                    <MasterDataDropdown
                      withNew
                      api="/api/confezioni"
                      object="Confezione"
                      startingValue={this.state.data.ConfezioniID}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, ConfezioniID: value }
                        })
                      }
                      editorForm={PackForm}
                      />

                  </Form.Group>

                  <Form.Group widths="equal">

                    <MasterDataDropdown
                      api="/api/categorie"
                      apiQueryParams="filter=leaf"
                      object="Categoria"
                      displayField="NomeCompleto"
                      startingValue={this.state.data.CategorieID}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, CategorieID: value }
                        })
                      }
                    />

                  </Form.Group>

                  <Form.Group widths="equal">

                    <Form.TextArea
                      label="Descrizione"
                      rows="3"
                      value={this.state.data.Descrizione}
                      onChange={(e, { value }) =>
                        this.setState({
                          data: { ...this.state.data, Descrizione: value }
                        })
                      } />

                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="right aligned"
          >
          {this.state.isEdit ?
            <Button icon className="left floated" style={{marginLeft: 0}}
              disabled={this.state.isLoading || this.state.isError}
              onClick={async function() {
                try {
                  await axios.request(
                    "/api/prodotti/" + this.props.match.params.productId,
                    {
                      method: "DELETE"
                    }
                  );
                  this.props.history.goBack();
                } catch (e) {
                  ErrorToast(
                    "Impossibile Eliminare " + this.props.object,
                    e
                  );
                }
              }.bind(this)}>
              <Icon name='trash alternate' />
              Elimina
            </Button> : undefined}
            <Button
              negative
              onClick={function() {
                this.props.history.goBack();
              }.bind(this)}
            >
              <Icon name='cancel' />
              Annulla
            </Button>
            <Button
              positive
              className="floated"
              disabled={
                !(this.state.data.Nome &&
                this.state.data.MarcaID &&
                this.state.data.CategorieID &&
                this.state.data.ConfezioniID &&
                this.state.data.UnitaMisuraConfezioniID &&
                this.state.data.QuantitaConfezione &&
                this.state.data.PezziMagazzino)
              }
              onClick={async () => {
                try {
                  await axios.request(
                    "/api/prodotti/" + (this.state.isEdit ? this.props.match.params.productId : ""),
                    {
                      method: this.state.isEdit ? "PUT" : "POST",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      data: JSON.stringify(
                        this.state.data
                      )
                    }
                  );
                  this.props.history.goBack();
                } catch (e) {
                  ErrorToast("Impossibile eseguire l'operazione", e);
                }
              }}
            >
              <Icon name='save outline' />
              Salva
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
