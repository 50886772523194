/* Impostazioni della Barra di Navigazione Superiore */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Icon,
  Menu,
  Sidebar,
  Dropdown
} from "semantic-ui-react";
import { Media } from "./Media"
import { UserData } from 'react-oidc'

/* Logo in alto a sinistra */
const AppLogo = (() =>
  <Menu.Item as={Link} to="/" header>
    <Icon disabled name='cubes' />
      Magazzino
    </Menu.Item>
  )

/* Icona profilo utente in alto a destra */
const ProfileItem = (() =>
  <UserData.Consumer>
    { context =>
      <Dropdown item icon='user'>
        <Dropdown.Menu>
          <Dropdown.Item disabled>
            {/* Dropdown è un menu a discesa e disabled significa che non è
                cliccabile
                Se context.user ESISTE allora impostalo con il nome dell'utente
                                   altrimente impostalo a DEBUG */}
            {context.user ? context.user.profile.name : 'DEBUG'}
          </Dropdown.Item>
          {/* procedura di LOGOUT */}
          <Dropdown.Item
            disabled={!context.user}
            onClick={() => {
              context.userManager.removeUser();
              window.location.href = (
                context.userManager.settings.metadata.end_session_endpoint +
                '?id_token_hint=' + encodeURIComponent(context.user.id_token)
              )
            }}
          >Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
  </UserData.Consumer>
)
// Impostazione della barra di navigazione per i dispositivi MOBILE
const NavBarMobile = ({
  children,
  leftItems,
  onPusherClick,
  onToggle,
  rightItems,
  visible
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible={visible}
      id="my-sidebar"
      style={{
        minWidth: "40%"
      }}
    >
      {leftItems.map(item => <Menu.Item onClick={onPusherClick} {...item} />)}
    </Sidebar>
    <Sidebar.Pusher
      //dimmed={visible}
      onClick={onPusherClick}
      style={{
        height: "100vh",
        overflowY: "scroll"
      }}
    >
      <Menu fixed="top" inverted>
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
        <AppLogo/>
        <Menu.Menu position="right">
          {rightItems.map(item => <Menu.Item {...item} />)}
          <ProfileItem/>
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

// Impostazione della barra di navigazione per i dispositivi DESKTOP
const NavBarDesktop = ({ leftItems, rightItems }) => (
  <Menu fixed="top" inverted>
    <AppLogo/>
    {leftItems.map(item => <Menu.Item {...item} />)}
    <Menu.Menu position="right">
      {rightItems.map(item => <Menu.Item {...item} />)}
      <ProfileItem/>
    </Menu.Menu>
  </Menu>
);

const NavBarChildren = ({ children }) => (
  <Container className="fillheightNoNavbar">{children}</Container>
);

export default class NavBar extends Component {
  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children, leftItems, rightItems } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <Media 
          at="sm"
          style={{
            top: 0,
            left: 0,
            overflow: "none"
          }}
        >
          <NavBarMobile
            leftItems={leftItems}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            rightItems={rightItems}
            visible={visible}
            style={{ height: "100vh" }}
          >
            <NavBarChildren>{children}</NavBarChildren>
          </NavBarMobile>
        </Media>
        <Media 
          className="container-desktop"
          greaterThan="sm"
        >
          <NavBarDesktop leftItems={leftItems} rightItems={rightItems} />
          <NavBarChildren>{children}</NavBarChildren>
        </Media>
      </div>
    );
  }
}
