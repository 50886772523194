import React, { Component } from 'react';
import { List, Grid, Header } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { UserData } from 'react-oidc'

export default class Home extends Component {

  state = {
  };

  componentDidMount() {

  }

  render() {
    return (
      <div  className="fillheight">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h1" content="Magazzino Home" />
              <UserData.Consumer>
                {context => <p>Ciao {context.user ? context.user.profile.name : 'DEBUG'},<br/>
                Benvenuto nell'app del Magazzino!</p>}
              </UserData.Consumer>
          </Grid.Column>
        </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h2" content="Reports" />
              <List divided relaxed>
                <List.Item>
                  <List.Icon name='calendar alternate outline' size='large'/>
                  <List.Content>
                    <Link to='/report/shelf-life'>Report Prodotti in Scadenza</Link>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='redo' size='large'/>
                  <List.Content>
                    <Link to='/report/reorder'>Riordino</Link>
                  </List.Content>
                </List.Item>
              </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
    );
  }
}
Home.contextType = UserData;
