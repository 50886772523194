  import React from "react";
import {
  Accordion,
  Icon,
  Input,
  Item,
  Label,
  Table,
  Button,
  Grid,
  Loader,
  Dropdown,
  Header
} from "semantic-ui-react";
import { Media } from "./Media";
import { Link } from "react-router-dom";
import ReactQueryParams from "react-query-params";
import EanScanner from "./EanScanner"
import axios from 'axios';

const limit = 30;

export default class Products extends ReactQueryParams {
  state = {
    activeProductId: -1,
    searchInProgress: false,
    searchInError: false,
    searchText: "",
    warehouses: [],
    warehousesInError: false,
    selectedWarehouse: undefined,
    selectedCategory: undefined,
    products: [],
    activeProductStock: [],
    offset: 0
  };

  timeoutHandler;

  // chiama l'API che è definita nel file server.js per leggere l'anagrafica dei  prodotti
  loadProducts = async function(search, magazzino, categoria, paginationOp) {
    try {

      let offset = this.state.offset;
      if (paginationOp === "NEXT"){
        offset = offset + limit;
      } else if (paginationOp === "PREV") {
        offset = offset - limit;
      } else if (paginationOp === "RESET") {
        offset = 0;
      }

      this.setState({
        searchInProgress: true,
        searchInError: false
      });

      // let definisce la variabile url
      let url = "/api/prodotti" +
        "?limit=" + limit +
        "&offset=" + offset +
        "&search=" + search;
      if (magazzino){
        // se il campo magazzino è stato selezionato passo oltre al prodotto da cercare anche il magazzino che userò come filtro
        url = url + "&magazzino=" + magazzino;
      }
      if (categoria){
        // se il campo categoria è stato selezionato passo oltre al prodotto da cercare anche la categoria che userò come filtro
        url = url + "&categoria=" + categoria;
      }
      /* definisce la costante response assegnandogli il risultato della funzione axios.get
        (la funzione axios.get restituisce molti campi tra cui body) */
      const response = await axios.get(url);
      // metto nella ostante body il risultato del campo body restituito dalla axios.get formato json
      const body = response.data;

      this.setState({
        products: body,
        searchInProgress: false,
        searchInError: false,
        offset: offset
      });

    } catch (e) {

      this.setState({
        products: [],
        searchInProgress: false,
        searchInError: true
      });

    }
  };

// chiama l'API che è definita nel file server.js per leggere l'anagrafica dei  magazzini
  loadWarehouses = async function() {
    try {
      const response = await axios.get("/api/magazzini/");
      const warehouses = response.data;
      this.setState({
        selectedWarehouse: isNaN(parseInt(this.queryParams.warehouse)) ? undefined : parseInt(this.queryParams.warehouse),
        warehouses: warehouses
      });
    } catch (e) {
      this.setState({
        warehousesInError: true
      })
    }
  };

  openProduct = async function(newProductId) {
    this.setState({
      activeProductId: newProductId,
      activeProductStock: []
    });

    this.setQueryParams({
      /* a activeProduct assegna newProductId SE (?) newProductId >= 0
           altrimenti assegnagli undefined  */
      activeProduct: newProductId >= 0 ? newProductId : undefined
    });

    if (newProductId !== -1) {
      const response = await axios.get(
        "/api/prodotti/" + newProductId + "/giacenza"
      );
      const stock = response.data;
      this.setState({
        activeProductStock: stock
      });
    }
  };

  performSearch = function(searchText, selectedWarehouse, selectedCategory, paginationOp) {
    searchText = searchText || "";
    if (searchText !== this.state.searchText ||
      selectedWarehouse !== this.state.selectedWarehouse ||
      selectedCategory !== this.state.selectedCategory) {
        paginationOp = "RESET";
    }
    this.setState({
      searchText: searchText,
      selectedWarehouse: selectedWarehouse,
      selectedCategory: selectedCategory
    });
    this.setQueryParams({
      search: searchText.length >= 3 ? searchText : undefined,
      warehouse: selectedWarehouse,
      category: selectedCategory
    });
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.setState({
        searchInProgress: false
      });
    }
    // lancio la ricerca solo se nel campo search ho scritto almeno 3 caratteri
    if (searchText.length >= 3 || selectedWarehouse || selectedCategory) {
      this.setState({
        searchInProgress: true,
        activeProductId: -1
      });
      if (searchText.length < 3){
        this.loadProducts("", selectedWarehouse, selectedCategory, paginationOp);
      } else {
        /* chiamo la funzione di ricerca dopo 500 msec dall'ultimo
        carattere scritto, è una ottimizzazione per non sovraccaricare
        il server di troppe ricerche mentre sto scrivendo la stringa da
        ricercare */
        this.timeoutHandler = setTimeout(
          function() {
            this.loadProducts(searchText, selectedWarehouse, selectedCategory, paginationOp);
          }.bind(this),
          500
        );
      }
    } else {
      // se ho scritto meno di 3 caratteri imposto l'array dei prodotti da restituire a vuoto
      this.setState({
        products: []
      });
    }
  };

  /* componentDidMount è la funzione che viene chiamata all'apertura della pagina */
  /* Al suo interno metto le funzioni da eseguire */
  componentDidMount = function() {
    /* Carico i magazzini da usare come filtro */
    this.loadWarehouses();
    /* queryParams è la parte di URL dopo il ?
        Se queryParams non è undefined e il campo di ricerca Search non è vuoto
        allora esegui performSearch passandogli il contenuto del campo */
    if (this.queryParams && (this.queryParams.search || this.queryParams.warehouse || this.queryParams.category)) {
      this.performSearch(decodeURI(this.queryParams.search || ""), this.queryParams.warehouse, this.queryParams.category);
    }
    /* usata per ricordare il codice del prodotto selezionato per riposizionarsi quando si ritorna alla pagina */
    if (this.queryParams && this.queryParams.activeProduct) {
      this.openProduct(parseInt(this.queryParams.activeProduct));
    }
  };



  render() {

    const plusButton = (
      <Link to="/products/$NEW">
        <Button icon className="right floated" style={{height: "38px"}}>
          <Icon name="plus" />
        </Button>
      </Link>
    );

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h2" content="Prodotti" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {/* Inizio definizione Colonna 1 - Seleziona il prodotto da cercare */}
          <Grid.Column mobile={16} tablet={16} computer={8} className="bottomSpace">
            
              {plusButton}

            <EanScanner
              trigger={
                  <Button
                  icon
                  className="right floated"
                  disabled={this.state.warehouses.length === 0}
                  style={{height: "100%"}}
                >
                  <Icon name="barcode" />
                </Button>
              }
              onDetected={(code) => {
                this.performSearch(code, this.state.selectedWarehouse, this.state.selectedCategory);
              }}
            />

            <Input
              value={this.state.searchText}
              placeholder="Cerca Prodotti ..."
              fluid
              labelPosition="right"
              type="text"
              disabled={this.state.warehouses.length === 0}
              onChange={(ev, data) => {
                this.performSearch(data.value, this.state.selectedWarehouse, this.state.selectedCategory);
              }}
            >
              <Label icon="search" />
              <input />
              <Label
                basic
                icon="remove"
                onClick={() => {
                  this.performSearch(undefined, this.state.selectedWarehouse, this.state.selectedCategory);
                }}
              />
            </Input>
          </Grid.Column>

          {/* Inizio definizione Colonna 2 - Seleziona il magazzino ove cercare */}
          <Grid.Column mobile={16} tablet={16} computer={8} className="bottomSpace">

            <Button as="div" labelPosition="left" fluid>

              <Dropdown
                className="label unsetBold"
                selection
                fluid
                value = {this.state.selectedWarehouse}
                icon="filter"
                placeholder="Magazzino"
                options={this.state.warehouses.map(function(obj) {
                  return {
                    key: obj.ID,
                    value: obj.ID,
                    text: obj.Nome
                  };
                })}
                onChange={(ev, data) => {
                  this.performSearch(this.state.searchText, data.value, this.state.selectedCategory);
                }}
                disabled={this.state.warehouses.length === 0}
              />
              <Button
                  icon
                  onClick={()=>{
                      this.performSearch(this.state.searchText, null, this.state.selectedCategory);
                      }}
                  disabled={this.state.warehouses.length === 0}
              >
                <Icon name="close" />
              </Button>

            </Button>

          </Grid.Column>
        </Grid.Row>

        {
          this.state.selectedCategory ?
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              Query per categoria attiva
            </Grid.Column>
          </Grid.Row>
          : undefined
        }

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            {(this.state.searchInProgress || (this.state.warehouses.length === 0 && !this.state.warehousesInError)) ? (
              <Loader active inline="centered" />
            ) : (this.state.searchInError || this.state.warehousesInError) ? (
              <Label color='red'>Error</Label>
            ) : (this.state.products.length > 0 ? (
              <Accordion styled fluid>
                {this.state.products.map(product => {
                  return (
                    <div key={product.IdProdotto}>
                      <Accordion.Title
                        active={
                          this.state.activeProductId === product.IdProdotto
                        }
                        index={product.IdProdotto}
                        onClick={this.handleAccordionClick}
                        style={{cursor:"default"}}
                      >
                        <Item.Group>
                          <Item style={{margin: "0px"}}>
                            <Item.Content style={{padding: "0px"}}>
                              <Item.Header style={{ width: "100%" }}>
                                <Link to={"/products/" + product.IdProdotto}>
                                    <Button icon className="right floated">
                                      <Icon name="edit" />
                                    </Button>
                                  </Link>
                                  <Link to={"/products/$NEW?copyFrom=" + product.IdProdotto}>
                                      <Button icon className="right floated">
                                        <Icon name="copy" />
                                      </Button>
                                    </Link>
                                <Icon name="dropdown" style={{marginLeft:"-8px"}}/>
                                {product.NomeProdotto}
                              </Item.Header>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column
                                    mobile={16}
                                    tablet={10}
                                    computer={10}
                                  >
                                    <Item.Description style={{color:"black"}}>
                                      <span>{product.Marca}</span>
                                    </Item.Description>
                                    <Item.Extra>
                                      <span>{product.Confezione}</span>
                                      <span> </span>
                                      <span>{product.Quantita}</span>
                                      <span> </span>
                                      <span className="spaceLeft nowrap">({product.PezziMagazzino} Pezzi)</span>
                                    </Item.Extra>
                                    <Item.Meta>
                                      <span>EAN: {product.EAN}</span>
                                    </Item.Meta>
                                  </Grid.Column>
                                  <Grid.Column
                                    mobile={16}
                                    tablet={6}
                                    computer={6}
                                  >
                                  <Grid>
                                    <Grid.Row>
                                      <Grid.Column mobile={8} tablet={16} computer={16} className="right aligned">
                                        <Button
                                          style={{marginTop:"4px", marginRight:"0px"}}
                                          onClick={function() {
                                            const index = product.IdProdotto;
                                            const { activeProductId } = this.state;
                                            const newProductId = activeProductId === index ? -1 : index;

                                            this.openProduct(newProductId);
                                          }.bind(this)}
                                        >
                                          Giacenza: {product.Giacenza}
                                        </Button>
                                      </Grid.Column>
                                      <Grid.Column mobile={8} tablet={16} computer={16} className="right aligned">
                                        <Link to={"/prices/" + product.IdProdotto}>
                                          <Button icon style={{marginTop:"4px", marginRight:"0px"}}>
                                            {product.PrezzoMedioPesatoUnitaPrincipale ?
                                              "Prezzo: ~ " + product.PrezzoMedioPesatoUnitaPrincipale +  "€/" + product.UnitaPrincipale :
                                              "Aggiungi Prezzo"
                                            }
                                          </Button>
                                        </Link>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>



                            </Item.Content>
                          </Item>
                        </Item.Group>
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          this.state.activeProductId === product.IdProdotto
                        }
                      >
                        <Grid reversed="computer" centered container style={{marginTop:"10px"}}>
                          <Grid.Column mobile={16} tablet={16} computer={6}>
                            <Grid centered>
                              <Grid.Column
                                mobile={8}
                                tablet={5}
                                computer={15}
                                className="center aligned"
                                style={{padding:"4px"}}
                              >
                                <Link to={"/loading/" + product.IdProdotto}>
                                  <Button className="floated">Carico</Button>
                                </Link>
                              </Grid.Column>
                              <Grid.Column
                                mobile={8}
                                tablet={5}
                                computer={15}
                                className="center aligned"
                                style={{padding:"4px"}}
                              >
                                <Link to={"/unloading/" + product.IdProdotto}>
                                  <Button className="floated">Scarico</Button>
                                </Link>
                              </Grid.Column>
                              <Grid.Column
                                mobile={16}
                                tablet={5}
                                computer={15}
                                className="center aligned"
                                style={{padding:"4px"}}
                              >
                                <Link to={"/handling/" + product.IdProdotto}>
                                  <Button className="floated">
                                    Trasferimento
                                  </Button>
                                </Link>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={10}>
                            <Table
                              basic="very"
                              celled
                              unstackable
                              className="left floated"
                            >
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Dispensa</Table.HeaderCell>
                                  <Table.HeaderCell>Prima Scadenza</Table.HeaderCell>
                                  <Table.HeaderCell>Giacenza</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                {this.state.activeProductStock.map(obj => {
                                  return (
                                    <Table.Row key={obj.IdMagazzino}>
                                      <Table.Cell>
                                        {obj.NomeMagazzino}
                                      </Table.Cell>
                                      <Table.Cell>{obj.MinDataScadenza}</Table.Cell>
                                      <Table.Cell>{obj.Giacenza}</Table.Cell>
                                    </Table.Row>
                                  );
                                })}
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid>
                      </Accordion.Content>
                    </div>
                  );
                })}
              </Accordion>
            ) : (
              <Label>No data</Label>
            ))}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
            {
              this.state.offset !== 0 ?
                <Button
                    icon
                    onClick={()=>{
                        this.performSearch(this.state.searchText, this.state.selectedWarehouse, this.state.selectedCategory, "PREV");
                        }}
                >
                  <Icon name="angle left" />
                  Prev
                </Button>
              : undefined
            }
            {
              this.state.products.length === limit ?
                <Button
                    icon
                    onClick={()=>{
                        this.performSearch(this.state.searchText, this.state.selectedWarehouse, this.state.selectedCategory, "NEXT");
                        }}
                >
                  Next
                  <Icon name="angle right" />
                </Button>
              : undefined
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
