// Gestione NEGOZI
import React, {Component} from "react";
import {
  Accordion,
  Item,
  Form
} from "semantic-ui-react";
import MasterDataList from "./MasterDataList";
import MasterDataDropdown from "./MasterDataDropdown";

const StoreItem = function (item, editorButton) {
  return (
      <div key={item.ID}>
      <Accordion.Title
        index={item.ID}
      >
        <Item.Group>
          <Item style={{margin: "0px"}}>
            <Item.Content style={{padding: "0px"}}>
                {editorButton}
              <Item.Header>
                {item.Nome}
              </Item.Header>
              <Item.Meta>
                <span>{item.Tipo}</span>
              </Item.Meta>
              <Item.Description>
                <span>{item.ComuneNome}</span>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Accordion.Title>
    </div>
  );
}

export const StoreForm = function () {
  return (<Form>
    <Form.Field>
      <label>Nome</label>
      <Form.Input
        placeholder="Nome"
        value={this.state.data.Nome || ""}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, Nome: value }
          })
        }
      />
    </Form.Field>
    <Form.Field>
    <label>Comune</label>
      <MasterDataDropdown
        api="/api/comuni"
        placeholder="Comune"
        startingValue={{
          ID: this.state.data.ComuneID,
          Nome: this.state.data.ComuneNome
        }}
        onChange={(e, { value }) =>
          this.setState({
            data: { ...this.state.data, ComuneID: value }
          })
        }
        />
    </Form.Field>
  </Form>)
}

export default class Stores extends Component {

  render() {
    return (
      <MasterDataList
        title="Negozi"
        api="/api/negozi"
        searchPlaceholder="Cerca Negozi ..."
        accordionItem={StoreItem}
        form={StoreForm}
        object="Negozio"
      />
    );
  }
}
