import React, { Component } from "react";
import {
  Button,
  Header,
  Form,
  Grid,
  Table,
  Icon
} from "semantic-ui-react";
import ErrorToast from "./ErrorToast";
import NumericInput from "react-numeric-input";
import axios from 'axios';

export default class Handling extends Component {
  state = {
    operationType: undefined,
    product: {},
    fromWarehouses: [],
    toWarehouses: [],
    selectedFromWarehouse: undefined,
    selectedToWarehouse: undefined,
    fromStock: []
  };

  loadProduct = async function(productId) {
    const response = await axios.get("/api/prodotti/" + productId);
    const product = response.data;
    this.setState({
      product: product
    });
  };

  loadFromWarehouses = async function() {
    const response = await axios.get(
      "/api/prodotti/" + this.state.product.IdProdotto + "/giacenza"
    );
    const warehouses = response.data.map(function (g) {
      return {
        ID: g.IdMagazzino,
        Nome: g.NomeMagazzino
      }
    });
    this.setState({
      fromWarehouses: warehouses
    });
    if (warehouses.length === 1){
      this.loadFromStock(warehouses[0].ID);
      this.setState({
        selectedFromWarehouse: warehouses[0].ID
      });
    }
  };

  loadToWarehouses = async function() {
    const response = await axios.get("/api/magazzini/");
    const warehouses = response.data;
    this.setState({
      toWarehouses: warehouses
    });
  };

  loadFromStock = async function(wId) {
    const response = await axios.get(
      "/api/prodotti/" + this.state.product.IdProdotto + "/giacenza/" + wId
    );
    const fromStock = response.data;
    this.setState({
      fromStock: fromStock.map(s => {
        s.QuantitaTrasferita = 0;
        return s;
      })
    });
  };

  componentDidMount = function() {
    let operationType;
    switch (this.props.match.path) {
      case "/handling/:productId":
        operationType = "handling";
        this.loadToWarehouses();
        break;
      case "/unloading/:productId":
        operationType = "unloading";
        break;
      default:
        operationType = undefined;
    }
    this.setState({
      operationType: operationType
    });

    this.loadProduct(this.props.match.params.productId).then(function () {
      this.loadFromWarehouses();
    }.bind(this));
  };

  warehousesOptions = function(warehouses) {
    return warehouses.map(function(obj) {
      return {
        key: obj.ID,
        value: obj.ID,
        text: obj.Nome
      };
    });
  };

  render() {
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header
              as="h2"
            >
                <Icon className="left floated" name='plug' />
                  {(() => {
                    switch (this.state.operationType) {
                      case "handling":
                        return "Trasferimento";
                      case "unloading":
                        return "Scarico";
                      default:
                        return "";
                    }
                  })()}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="center aligned"
          >
            <Header as="h3" content={this.state.product.NomeProdotto} />
            <span>{this.state.product.Marca}</span>
            <br />
            <span>{this.state.product.Confezione}</span>
            <span> </span>
            <span>{this.state.product.Quantita}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Form>
              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="DA"
                  options={this.warehousesOptions(this.state.fromWarehouses).filter(w => {
                    return w.key !== this.state.selectedToWarehouse;
                  })}
                  placeholder="Magazzino Prelievo"
                  onChange={(ev, data) => {
                    this.setState({
                      selectedFromWarehouse: data.value
                    });
                    this.loadFromStock(data.value);
                  }}
                  value={this.state.selectedFromWarehouse}
                />
                {this.state.operationType === "handling" ? (
                  <Form.Select
                    fluid
                    label="A"
                    options={this.warehousesOptions(this.state.toWarehouses).filter(w => {
                      return w.key !== this.state.selectedFromWarehouse;
                    })}
                    placeholder="Magazzino Destinazione"
                    onChange={(ev, data) => {
                      this.setState({
                        selectedToWarehouse: data.value
                      });
                    }}
                  />
                ) : (
                  undefined
                )}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="right aligned"
          >
            <Table basic="very" celled unstackable className="left floated">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Scadenza</Table.HeaderCell>
                  <Table.HeaderCell>Quantità DA</Table.HeaderCell>
                  <Table.HeaderCell>
                    {(() => {
                      switch (this.state.operationType) {
                        case "handling":
                          return "Quantità Trasferita";
                        case "unloading":
                          return "Quantità Consumata";
                        default:
                          return "";
                      }
                    })()}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.fromStock.map((s, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{s.DataScadenza}</Table.Cell>
                      <Table.Cell>{s.Quantita}</Table.Cell>
                      <Table.Cell>
                        <NumericInput
                          value={s.QuantitaTrasferita}
                          min={0}
                          max={s.Quantita}
                          size={2}
                          mobile
                          inputMode="numeric"
                          onChange={v => {
                            const fromStock = this.state.fromStock;
                            fromStock[i].QuantitaTrasferita = v;
                            this.setState({
                              fromStock: fromStock
                            });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row>
                  <Table.Cell>TOTALE</Table.Cell>
                  <Table.Cell>
                    {this.state.fromStock.reduce((a, c) => {
                      return a + c.Quantita;
                    }, 0)}
                  </Table.Cell>
                  <Table.Cell>
                    {this.state.fromStock.reduce((a, c) => {
                      return a + c.QuantitaTrasferita;
                    }, 0)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="right aligned"
          >
            <Button
              className="floated"
              disabled={
                !(
                  this.state.selectedFromWarehouse >= 0 &&
                  (this.state.selectedToWarehouse >= 0 ||
                    this.state.operationType === "unloading") &&
                  this.state.fromStock.reduce((a, c) => {
                    return a + c.QuantitaTrasferita;
                  }, 0) > 0
                )
              }
              onClick={async () => {
                try {
                  switch (this.state.operationType) {
                    case "handling":
                      await axios.request(
                        "/api/prodotti/" +
                          this.state.product.IdProdotto +
                          "/giacenza/" +
                          this.state.selectedFromWarehouse +
                          "/trasferimento/" +
                          this.state.selectedToWarehouse,
                        {
                          method: "PUT",
                          headers: {
                            "Content-Type": "application/json"
                          },
                          data: JSON.stringify(
                            this.state.fromStock
                              .map(s => {
                                return {
                                  DataScadenza: s.DataScadenza,
                                  Quantita: s.QuantitaTrasferita
                                };
                              })
                              .filter(s => {
                                return s.Quantita > 0;
                              })
                          )
                        }
                      );
                      break;
                    case "unloading":
                      await axios.request(
                        "/api/prodotti/" +
                          this.state.product.IdProdotto +
                          "/giacenza/" +
                          this.state.selectedFromWarehouse +
                          "/scarico",
                        {
                          method: "PUT",
                          headers: {
                            "Content-Type": "application/json"
                          },
                          data: JSON.stringify(
                            this.state.fromStock
                              .map(s => {
                                return {
                                  DataScadenza: s.DataScadenza,
                                  Quantita: s.QuantitaTrasferita
                                };
                              })
                              .filter(s => {
                                return s.Quantita > 0;
                              })
                          )
                        }
                      );
                      break;
                    default:
                  }
                  this.props.history.goBack();
                } catch (e) {
                  ErrorToast("Impossibile eseguire l'operazione", e);
                }
              }}
            >
              OK
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
