import React, {Component} from "react";
import {
  Modal,
  Grid,
  Form,
  Button,
  Icon,
  Loader,
  Segment,
  Dimmer
} from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react';
import ErrorToast from "./ErrorToast";
import { StoreForm } from "./Stores";
import MasterDataDropdown from "./MasterDataDropdown";
import axios from 'axios';
import moment from "moment";

export default class PricesEditor extends Component {

  state = {
    open: false,
    loading: false,
    date: undefined,
    store: undefined,
    price: undefined,
    promotion: false
  }

  triggerOnClose = function (updated) {
    if (typeof this.props.onClose === 'function'){
      this.props.onClose(updated)
    }
  }

  render() {
    return (
      <Segment as={Modal}
        open={this.state.open}
        trigger={this.props.trigger}
        className="fixModal"
        onOpen={() => {
          this.setState({
            open: true,
            loading: false,
            date: this.props.date,
            store: this.props.store,
            price: this.props.price,
            promotion: this.props.date || false
          });

        }}>
          <Dimmer active={this.state.loading} inverted>
            <Loader />
          </Dimmer>
          <Modal.Header>
            Aggiungi Prezzo - {this.props.product.NomeProdottoCompleto}
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  style={{overflowX:"auto"}}
                >
                  <DateInput
                    inline
                    value={this.state.date || ""}
                    maxDate={moment()}
                    dateFormat="YYYY-MM-DD"
                    startMode="day"
                    onChange={(event, {name, value}) => {
                      this.setState({
                        date: value
                      });
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                >
                  <Form>
                    <Form.Group widths="equal">
                      <MasterDataDropdown
                        withNew
                        api="/api/negozi"
                        object="Negozio"
                        displayField="NomeCompleto"
                        startingValue={this.state.store}
                        onChange={(e, { value }) =>
                          this.setState({
                            store: value
                          })
                        }
                        editorForm={StoreForm}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        type="number"
                        placeholder="Prezzo"
                        label="Prezzo"
                        value={this.state.price}
                        onChange={(e, { value }) => {
                          const numericValue =
                            isNaN(value)
                            ? undefined
                            : Math.max(parseFloat(Math.abs(value).toFixed(2)), 0)
                          this.setState({
                            price: numericValue
                          })
                        }}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Promozione</label>
                        <Form.Checkbox
                          toggle
                          checked={this.state.promotion}
                          onChange={(e, {checked}) => {
                            this.setState({
                              promotion: checked
                            })
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={function() {
                this.setState({
                  open: false
                });
                this.triggerOnClose(false);
              }.bind(this)}
            >
              <Icon name='cancel' />
              Annulla
            </Button>
            <Button
              positive
              disabled={!this.state.date || !this.state.store || isNaN(this.state.price)}
              onClick={async function() {
                try {
                  this.setState({
                    loading: true
                  });
                  await axios.request(
                    "/api/prodotti/" + this.props.product.IdProdotto + "/prezzi",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      data: JSON.stringify({
                        NegozioID: this.state.store,
                        Prezzo: this.state.price,
                        Promozione: this.state.promotion ? 1 : 0,
                        Data: this.state.date
                      })
                    }
                  );
                  this.setState({
                    open: false
                  });
                  this.triggerOnClose(true);
                } catch (e) {
                  this.setState({
                    loading: false
                  });
                  ErrorToast(
                    "Impossibile Aggiungere il Prezzo", e
                  );
                }
              }.bind(this)}
            >
              <Icon name='save outline' />
              Salva
            </Button>
          </Modal.Actions>
        </Segment>
    );
  }
}
