import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  makeAuthenticator,
  makeUserManager,
  Callback
} from 'react-oidc'
import App from "./App";
import axios from 'axios';

class Auth extends Component {


  render() {

    const userManagerConfig = this.props.oidcConfig;

    let userManager, AppWithAuth;

    if (userManagerConfig.active) {

      userManager = makeUserManager(userManagerConfig)

      userManager.getUser().then(function (user) {
        if (user){
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.id_token;
        }
      })

      userManager.events.addUserLoaded(function(user){
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.id_token;
      });

      AppWithAuth = makeAuthenticator({
        userManager: userManager,
        signinArgs: {
          state: {
            foo: 15
          }
        }
      })(App)

    }

    return (
      <Router>
        {this.props.oidcConfig.active ?
          <Switch>
            <Route
              path="/login/callback"
              render={routeProps => (
                <Callback
                  onSuccess={user => {
                    routeProps.history.push('/')
                  }}
                  userManager={userManager}
                />
              )}
            />
            <AppWithAuth />
          </Switch>
           :
          <App/>}
      </Router>
    );
  }
}

export default Auth;
