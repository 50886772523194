import React, { Component } from 'react'
import { Form, Icon } from 'semantic-ui-react'
import MasterDataEditor from "./MasterDataEditor";
import axios from 'axios';

const limit = 30;
const minSearchSize = 0;

export default class MasterDataDropdown extends Component {

  initialProps;

  constructor(props) {
    super(props);

    let startingValue;
    const startingOptions = [];
    if (typeof props.startingValue === "object"){
      startingValue = props.startingValue.ID;
      startingOptions.push({
        key: props.startingValue.ID,
        text: props.startingValue.Nome,
        value: props.startingValue.ID
      });
    } else {
      startingValue = props.startingValue;
    }

    this.state = {
      searchInProgress: false,
      search: true,
      searchQuery: null,
      value: startingValue,
      options: startingOptions,
    }
  }

  timeoutHandler;

  loadOptions = async function(search) {
    try {

      this.setState({
        searchInProgress: true,
      });

      // let definisce la variabile url
      let url = this.props.api +
        "?limit=" + limit +
        "&search=" + search +
        "&" + this.props.apiQueryParams;

      /* definisce la costante response assegnandogli il risultato della funzione axios.get
        (la funzione axios.get restituisce molti campi tra cui body) */
      const response = await axios.get(url);
      // metto nella costante body il risultato del campo body restituito dalla axios.get formato json
      const body = response.data;

      const displayField = this.props.displayField || "Nome";

      this.setState({
        options: body.map(function (obj) {
            return {
              key: obj.ID,
              text: obj[displayField],
              value: obj.ID
            }
          }),
        searchInProgress: false
      });

    } catch (e) {

      this.setState({
        options: [],
        searchInProgress: false
      });

    }
  };

  loadOptionsByKey = async function(key) {
    try {

      this.setState({
        searchInProgress: true,
      });

      // let definisce la variabile url
      let url = this.props.api + '/' + key;

      /* definisce la costante response assegnandogli il risultato della funzione axios.get
        (la funzione axios.get restituisce molti campi tra cui body) */
      const response = await axios.get(url);
      // metto nella costante body il risultato del campo body restituito dalla axios.get formato json
      const body = response.data;

      const displayField = this.props.displayField || "Nome";

      this.setState({
        options: [{
              key: body.ID || '?',
              text: body[displayField],
              value: body.ID
            }],
        searchInProgress: false
      });

    } catch (e) {

      this.setState({
        options: [],
        searchInProgress: false
      });

    }
  };

  performSearch = function(searchQuery) {
    searchQuery = searchQuery || "";

    this.setState({
      searchQuery: searchQuery
    });

    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.setState({
        searchInProgress: false
      });
    }
    // lancio la ricerca solo se nel campo search ho scritto almeno 3 caratteri
    if (searchQuery.length >= minSearchSize) {
      this.setState({
        searchInProgress: true,
      });
      if (searchQuery.length < minSearchSize){
        this.loadOptions("");
      } else {
        /* chiamo la funzione di ricerca dopo 500 msec dall'ultimo
        carattere scritto, è una ottimizzazione per non sovraccaricare
        il server di troppe ricerche mentre sto scrivendo la stringa da
        ricercare */
        this.timeoutHandler = setTimeout(
          function() {
            this.loadOptions(searchQuery);
          }.bind(this),
          500
        );
      }
    } else {
      // se ho scritto meno di 3 caratteri imposto l'array dei prodotti da restituire a vuoto
      this.setState({
        options: []
      });
    }
  };

  handleChange = function (e, o) {
     this.setState({ value: o.value })
     if (typeof this.props.onChange === 'function'){
       this.props.onChange(e, o);
     }
  }.bind(this)

  handleSearchChange = function (e, { searchQuery }) {
    this.performSearch(searchQuery)
  }.bind(this)

  componentDidMount = function () {
    if (!this.state.value) {
      this.performSearch();
    } else if (this.state.value && this.state.options.length === 0) {
      this.loadOptionsByKey(this.state.value);
    }
  }


  render() {
    return (
      <Form.Input action fluid style={{alignItems: "flex-end"}}>
          <Form.Select
            className="masterDataDropdownSelect"
            selection
            label={this.props.label || this.props.object}
            search={this.state.search}
            options={this.state.options}
            value={this.state.value}
            placeholder={this.props.placeholder || this.props.object}
            onChange={this.handleChange}
            onSearchChange={this.handleSearchChange}
            disabled={this.state.searchInProgress}
            loading={this.state.searchInProgress}
          />
          {
            this.props.withNew ?
            <MasterDataEditor
              trigger={
                <Form.Button
                  icon
                  disabled={this.state.searchInProgress}
                  style={{height: "38px", marginLeft: "3px"}}>
                  <Icon name="plus"/>
                </Form.Button>
              }
              onClose={(updated, element) => {
                if (updated && element){
                  this.loadOptionsByKey(element.ID);
                  this.handleChange({}, {
                    value: element.ID
                  });
                }
              }}
              form={this.props.editorForm}
              api={this.props.api}
              object={this.props.object}
            />
            :
            undefined
          }
        </Form.Input>
    )
  }
}
